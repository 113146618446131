import { toast } from "bulma-toast";

document.addEventListener('DOMContentLoaded', () => {
  // notifications - toast
  document.querySelectorAll('#notifications > div').forEach(elm => {
    toast({
      message: elm,
      type: elm.className,
      dismissible: true,
      pauseOnHover: true,
      duration: 5000,
      opacity: 0.8,
    });
  });
});
