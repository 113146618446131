document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.show-request').forEach(elm => {
    elm.addEventListener('click', function () {

      if (elm.classList.contains('open')) {
        elm.classList.remove('open');
        elm.nextElementSibling.classList.remove('open');
        elm.innerHTML = 'Show';
      } else {
        elm.classList.add('open');
        elm.nextElementSibling.classList.add('open');
        elm.innerHTML = 'Hide';
      }
    });
  });
}); 